import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Features from '../components/Features'
// import BlogRoll from '../components/BlogRoll'

export const IndexPageTemplate = ({title, subheading, mainpitch, heading, description, intro, image}) => (
  <div>      
      <section id="banner"        
        style={{
          backgroundImage: `url(${
            !!image.childImageSharp ? image.childImageSharp.fluid.src : image
          })`,
          backgroundPosition: `center`,
          backgroundAttachment: `fixed`                     
        }}>
        <div className="inner">
          <h1 className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
          style={{
             boxShadow:
               'rgb(19, 95, 56) 0.5rem 0px 0px, rgb(19, 95, 56) -0.5rem 0px 0px',
             backgroundColor: 'rgb(19, 95, 56)',
             color: 'white',
             lineHeight: '1',
             padding: '0.25em',
             textTransform: 'none'
           }}>{title}					
          </h1>					
          <h3  className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen"         
           style={{
             boxShadow:
               'rgb(19, 95, 56) 0.5rem 0px 0px, rgb(19, 95, 56) -0.5rem 0px 0px',
             backgroundColor: 'rgb(19, 95, 56)',
             color: 'white',
             lineHeight: '1',
             padding: '0.25em',
             textTransform: 'none'
           }}
          >
           {subheading}
         </h3>				
         </div>
			</section>
		
			<section id="one">
				<div className="inner">
					<header>
						<h2>{mainpitch.title}</h2>
					</header>
          <p>{description}</p>
					<p>{mainpitch.description}</p>          
					{/* <ul class="actions">
						<li><button class="button alt">Learn More</button></li>
					</ul> */}
				</div>
			</section>
      <Features gridItems={intro.blurbs} />
  </div>  
)

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark  
  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 50) {
              ...GatsbyImageSharpFluid
            }
          }
        }        
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {            
            image {
              childImageSharp {
                fluid(maxWidth: 1300, quality: 70) {
                  ...GatsbyImageSharpFluid
                }
              }
            }          
            text
            title
            nutrients {
              nutrient
              symbol
              unit
              analysis
            }          
          }
          heading
          description
        }
      }
    }
  }
`
