import React from 'react'
import PropTypes from 'prop-types'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import MarkdownContent from '../components/MarkdownContent'

const FeatureGrid = ({ gridItems }) => (
  <section id="two">
    <div className="inner">
      {gridItems.map(item=>(
        <article key={item.title}>
          <div className="content">
            <header>
              <h3>{item.title}</h3>
            </header>
            <div className="image fit">
              <PreviewCompatibleImage imageInfo={item} />
            </div>                          
            <p>{item.text}</p>
            <table className="nutrients">
              <thead>
                <tr>
                  <th>Chất dinh dưỡng</th>
                  <th>Ký hiệu</th>
                  <th>Đơn vị</th>
                  <th>Phân tích</th>
                </tr>                
              </thead>
              <tbody>
                {item.nutrients.map(n=>(
                  <tr key={n.nutrient}>
                    <td><MarkdownContent content= {n.nutrient}/></td>
                    <td><MarkdownContent content = {n.symbol}/></td>
                    <td>{n.unit}</td>
                    <td><MarkdownContent content = {n.analysis}/></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </article>
      ))}
    </div>
  </section>
)

FeatureGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
      title: PropTypes.string
    })
  ),
}

export default FeatureGrid
